import React from "react";
import styled, {keyframes} from "styled-components";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

const CustomMarquee = ({ componentsList }) => {
    const { i18n } = useTranslation("common");
    const language = i18n.language;
    return (
        <div className="overflow-hidden flex">
            <MarqueeGroup
                className="marquee-group w-full flex gap-8 hover:animation-paused"
                direction={language === "en" ? "left" : "right"}
                start="0"
                end={language === "en" ? "-100" : "100"}
            >
                {componentsList.map((component, index) => {
                    return (
                        <span className={"text-sm whitespace-nowrap"} key={index}>
              {component}
            </span>
                    );
                })}
            </MarqueeGroup>
        </div>
    );
};

const scrollX = (direction, start, end) => keyframes`
  from  {
    ${direction}: translateX(${start}%);
  }
  to  {
    transform: translateX(${end}%)
  }
`;

const MarqueeGroup = styled.div`
  animation: ${(props) => scrollX(props.direction, props.start, props.end)} 10s
  linear infinite;
`;

export default CustomMarquee;
