import React from "react";
import Reports from "../../components/reports";
import Clients from "./clients";
import Hero from "./hero";
import Stats from "./stats";
import Values from "./values";
import {useGetAboutPageQuery} from "../../features/api/api-slice";
import TabTitle from "../../components/tab-title";
import SEO from "../../components/seo";

const About = () => {
  const { data: aboutPageData } = useGetAboutPageQuery();
  const pageInfo = aboutPageData?.data?.pageInfo;
  const reports = aboutPageData?.data?.reports;
  const values = aboutPageData?.data?.features;
  const stats = aboutPageData?.data?.statistics;
  const partners = aboutPageData?.data?.partners;
  const info = pageInfo && {
    title: pageInfo.title,
    description: pageInfo.description,
    link: window.location.href,
    image: pageInfo.coverImage,
  };
  return (
    <>
      {pageInfo && (
        <>
          <TabTitle title={pageInfo.title} />
          <SEO info={info} />
        </>
      )}
      <main className={``}>
        <section>{pageInfo && <Hero heroInfo={pageInfo} />}</section>
        <section className="py-10">
          {values && <Values valuesInfo={values} />}
        </section>
        <section className="contain-it-[95-1080] py-10">
          {reports && <Reports reportsInfo={reports} />}
        </section>
        <section className="contain-it-[95-1080] py-10">
          {stats && <Stats statsInfo={stats} />}
        </section>
        <section>{partners && <Clients clientsInfo={partners} />}</section>
      </main>
    </>
  );
};

export default About;
