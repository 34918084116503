import React from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade} from "swiper";
import "swiper/css";

const MainSlider = ({ slidesInfo }) => {
    return (
        <Swiper
            effect="fade"
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, EffectFade]}
        >
            {slidesInfo.map((slideInfo, index) => {
                return (
                    <SwiperSlide key={index}>
                        <SingleSlide slideInfo={slideInfo} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

const SingleSlide = ({ slideInfo }) => {
    return (
        <div className="">
            <CustomSlide
                className="min-h-[350px] md:min-h-[540px] bg-no-repeat bg-cover flex relative"
                imageUrl={slideInfo.image}
            >
                <div className="text py-4 text-white text-3xl w-full bg-gradient-to-t from-black absolute right-0 h-full flex items-end">
                    <h2 className="contain-it-[95-1080] py-5">{slideInfo.title}</h2>
                </div>
            </CustomSlide>
        </div>
    );
};

const CustomSlide = styled.div`
  background-image: url(${(props) => props.imageUrl});
`;

export default MainSlider;
