import {Outlet} from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import {useTranslation} from "react-i18next";
import Share from "../components/share";
import {useGetGlobalsQuery} from "../features/api/api-slice";
import React, {useEffect, useState} from "react";

export default function Layout() {
    const { t, i18n } = useTranslation("common");
    const { data: globalsData } = useGetGlobalsQuery();
    const header = globalsData?.data?.header[0];
    const footer = globalsData?.data?.footer;
    const social = globalsData?.data?.social;

    const [showLoader, setShowLoader] = useState(true);


    setTimeout(() => {
        setShowLoader(false)
        setTimeout(() => {
            document.getElementById("loader").classList.add("invisible")
        }, 200);
    }, 3000);

    return (
        <div
            className={`${
                i18n.language === "en" ? "ltr text-left" : "rtl text-right"
            }`}
        >
            <div id={'loader'} className={`
            fixed inset-0 w-full h-full z-[10000000000] bg-white flex items-center justify-center opacity-100 transition duration-300 ${showLoader ? "" : "!opacity-0" } `}>
                <img
                    src="/assets/images/logo.png?v=1"
                    alt=""
                    className={`max-w-[300px]`}
                />
            </div>

            {header && <Header headerInfo={header} />}
            <main className={`min-h-[65vh]`}>
                <div className="fixed top-1/2 z-[401] -translate-y-1/2 hidden md:block">
                    <Share />
                </div>
                <Outlet />
            </main>
            {footer && <Footer footerInfo={footer} socialInfo={social} />}
        </div>
    );
}
