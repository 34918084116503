import React from "react";
import clientsImage from "../../assets/images/a1.jpg";
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import styled from "styled-components";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import {useTranslation} from "react-i18next";

const Clients = ({ clientsInfo }) => {
  const { t } = useTranslation("common");
  return (
    <div className="relative">
      <div className="image brightness-50 h-60 overflow-hidden">
        <img src={clientsImage} alt="" className="w-full object-fill blur-sm" />
      </div>
      <div className="clients absolute w-full top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
        <div className="title text-xl text-white text-center py-3">
          <h2>{t("partners.partners")}</h2>
        </div>
        <CustomSwiper className="clients-list contain-it-[95-1080] bg-white rounded py-4">
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: ".prev",
              prevEl: ".next",
            }}
            className="relative"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              560: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={30}
          >
            {clientsInfo.map((client, index) => {
              return (
                <SwiperSlide key={index}>
                  <ClientLogo clientLogo={client.logo} />
                </SwiperSlide>
              );
            })}
            <div className="prev text-3xl absolute left-2 top-1/2 -translate-y-1/2 cursor-pointer z-10 transition-all duration-200 hover:bg-gray-800 p-1 rounded-full">
              <FiArrowLeft />
            </div>
            <div className="next text-3xl absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer z-10 transition-all duration-200 hover:bg-gray-800 p-1 rounded-full">
              <FiArrowRight />
            </div>
          </Swiper>
        </CustomSwiper>
      </div>
    </div>
  );
};

const ClientLogo = ({ clientLogo }) => {
  return (
    <div className="overflow-hidden h-24 flex justify-center">
      <img src={clientLogo} alt="" className="w-auto h-full" />
    </div>
  );
};

const CustomSwiper = styled.div`
  .swiper-button-disabled {
    color: gray;
  }
`;

export default Clients;
